
import { defineComponent } from 'vue'
import Button from "@/components/Button.vue"
import FlipCardNew from '@/components/FlipCardNew.vue'
import Counter from "@/components/Counter.vue"
import Dialog from "@/components/Dialog.vue"
import Toast from "@/components/Toast.vue"
//router
import router from '../router'

// Utils
import utilities from "../utilities"

export default defineComponent({
  name: "AskCard",
  components: {
    Button,
    FlipCardNew,
    //rCounter,
    Dialog,
    Toast
  },
  data() {
    return {
      token: '',
      validation: {
        data: {}
      } as any[any],
      loadingValidation: true,
      cards: [] as any[any],
      step: 1,
      uploadButtontext: 'Subir PDF',
      loading: false,
      loadingText: 'Cargando...',
      cardPreview: false,
      showRemoveDialog: false,
      onConfirmRemove: Function as any,
      onCancelRemove: Function as any,
      currentFile: [] as any[any],
      basePrice: 0,
      subtotal: 0,
      products: [] as any[any],
      isHover: {} as any[any],
    }
  },
  async mounted() {
    //check auth and get token
    //let query = this.$route.query['to'] ? '?to='+ this.$route.query['to']: ''
    let checkAuth = utilities.checkAuth('validar-credencial') as any
    Promise.resolve(checkAuth).then((data) => {
      this.token = data.token as any
    })

    let valid = utilities.isValidated() as any
    Promise.resolve(valid).then((data) => {
      this.loadingValidation = false
      this.validation = data as any
      data['verification'] = 'correct'
      data['full_name'] = data.data['fullname']
      this.cards.push(Object(data))
    })

    //get config
    let config = await utilities.getItems('config/1?fields=*', this.token) as any

    //base price for credential
    this.basePrice = config.credential_price

    //get products
    let products = await utilities.getItems('products?fields=*.*.*') as any
    this.products = products

    //set default variation
    this.products.forEach((product: any, productIndex: number) => {
      this.products[productIndex].selectedVariation = this.products[productIndex].variations[0]
    })
  },
  methods: {
    createCredential: async function (e: any) {
      e.preventDefault();

      // get file input
      const input = e.target

      // display loading
      this.loading = true

      Array.from(input.files).reduce(async (previousPromise, file: any) => {
        await previousPromise

        // set loading
        this.loadingText = 'Subiendo Archivo'

        // upload file
        const fileUpload = utilities.uploadFile(file, this.token);

        // set ocr an qr promises
        let qrDecoded = Promise

        await Promise.resolve(fileUpload).then(async (file) => {
          if (file.error) {
            this.loading = false;
            (this.$refs.toast as any).show("Hubo un error al subir el archivo");
            throw file.error;
          }

          // set loading text
          this.loadingText = "Extrayendo texto";

          // set loading text
          this.loadingText = "Decodificando QR";

          // start qr decoding
          qrDecoded = utilities.qrDecode(file.data.full_url) as any;

          await Promise.resolve(qrDecoded).then(async (value: any) => {
            if (value.error || !value.link) {
              this.loading = false;
              (this.$refs.toast as any).show("No se pudo detectar QR");
              throw value.error;
            }

            // set uploaded file data
            let uploadedFile = {} as any;

            console.log(value)

            uploadedFile.full_name = value.text.name.toUpperCase();
            uploadedFile.doc = value.text.dni;
            uploadedFile.location = value.text.province;
            uploadedFile.street = value.text.street;
            uploadedFile.apt = value.text.floor;
            uploadedFile.zip_code = value.text.zip_code;
            uploadedFile.permit_id_nbr = value.text.id;
            uploadedFile.emission_date = value.text.emission_date;
            uploadedFile.expiration_date = value.text.expiration_date;
            uploadedFile.plants_qty_nbr = "9";
            uploadedFile.qr_link_string = value.link as any;
            uploadedFile.quantity = 1;
            uploadedFile.pdf = file.id;
            uploadedFile.item_price = this.basePrice;

            // uset loading
            this.loading = false;

            // set loading text
            this.loadingText = "Esperando verificación";

            // show preview
            this.showPreview(uploadedFile);
          });
          return Promise.resolve();
        })
      }, Promise.resolve())
    },
    showPreview: function (uploadedFile: Array<any>) {
      this.currentFile = uploadedFile
      this.verifyData('correct')

      //this.cardPreview = true
    },
    verifyData: function (status: string) {
      const toast = this.$refs.toast as any

      // start orc
      utilities.validateUser(this.currentFile['qr_link_string'], this.currentFile['full_name'], this.currentFile['expiration_date'], this.currentFile['pdf']).then((data: any[any]) => {
        this.currentFile['verification'] = status
        this.cards.push(Object(this.currentFile))
        toast.show('Credencial validada!', 'ok')
        if (this.$route.query['to']) {
          router.push('/' + this.$route.query['to'] + '?validation=ok')
        }
      }).catch(() => {
        toast.show("Por favor refresca la página y vuelve a intentar. Si el problema persiste comunicate con nosotros por WhatsApp cliqueando acá!", "validation_error");
        this.currentFile = []
      }) as any

    },

    nextStep(step: number) {
      // step 1
      if (step === 1) {
        if (this.cards.length > 0) {
          this.$store.commit('setItems', this.cards)
          this.step++
        } else {
          (this.$refs.toast as any).show('No aregaste archivos')
        }
      }
    },
    setVariation(productIndex: number, variarionIndex: number) {
      this.products[productIndex].selectedVariation = this.products[productIndex].variations[variarionIndex]
    },
    addProduct(product: any) {
      this.$store.commit('addProduct', product)
      const toast = this.$refs.toast as any
      toast.show('Agregaste un producto al carrito')
    },
    setHover(productIndex: number) {
      this.isHover[productIndex] = true
    },
    unsetHover(productIndex: number) {
      this.isHover[productIndex] = false
    }
  }
})
